































































































































































































.start-action {
  color: #1f2129;
  user-select: none;
  cursor: pointer;
  border: 2px dashed #9298ad;
  padding: 16px 12px;
  min-width: 145px;
  display: inline-block;
  font-weight: 700;

  &.trigger-action {
    background-color: #31a952;
    color: #fff;
    border: 1px solid #2a9c4a;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.13);
    border-radius: 5px;
  }

  .block-controls {
    color: #4b4b5a;
  }
}

.list-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  flex-wrap: wrap;

  .trigger-item {
    display: inline-block;
    text-align: center;
    margin: 5px;
    cursor: pointer;
    width: 105px;

    .icon {
      width: 96px;
      height: 96px;
      border: 2px solid #ced3e0;
      border-radius: 10px;
      margin-bottom: 9px;
      position: relative;
      text-align: center;
      line-height: 92px;
      color: #ced3e0;

      .uil {
        font-size: 46px;
      }
    }

    .label {
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      color: #414141;
    }

    &:hover,
    &.active {
      .icon {
        border-color: #497ab8;
        color: #497ab8;
      }
    }
  }
}